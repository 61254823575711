import React, {useState} from 'react';
import { useHistory, useParams} from "react-router-dom";
import { Dialog } from '@headlessui/react';

import DepositComponent from '../../components/DepositComponent';
import ModalComponent from '../../components/ui/ModalComponent';

const ReportUserPage = ({}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {userId} = useParams();
  let history = useHistory();

  function closeModal(event) {
    //event.preventDefault();
    setIsOpen(false);
    history.goBack();
  }
  return (
        <Dialog open={isOpen} onClose={(e) => closeModal(e)} className="modal fade absolute z-50">
           <Dialog.Panel className="modal fade fixed left-0 top-0 px-16 py-10 h-full backdrop-blur-md">
            <div className="rounded-xl bg-gray-300">
              <DepositComponent userId={userId} />
            </div>
           </Dialog.Panel>
        </Dialog>
  );
};

export default ReportUserPage;
