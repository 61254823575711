import React, {useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import {
  RiNotification3Line,
  RiArrowDownSLine,
  RiSettings3Line,
  RiLogoutCircleRLine,
  RiThumbUpLine,
  RiChat3Line,
} from "react-icons/ri";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import useAuth from "../../../hooks/useAuth";
import ImageNoFound from "../../../assets/png/avatar.png";
//import { activeMessage } from "../../../__mocks__/activeMessage";
import {getNotifications} from "../../../services/SocketService";
import {getFormatDate } from '../../../utils/winforUtil';

const RightHeaderComponent = () => {
  const {auth, logout, socket} = useAuth();
  const history = useHistory();
  const [message, setMessage] = useState([]);
  useEffect(()=>{
    //const activeMessage = getNotifications(socket);
    //console.log("consulto mensajes", activeMessage);
    //setMessage(activeMessage);
    socket.on("getNotification", data=>{
      setMessage(data);   //setMessage((prev) => [...prev, data]);
    })
    //console.log("consulto mensajes", message);
    //fetchMessage();
  }, [socket]);
  const onLogout = () =>{
    logout();
    history.push("/");
  }

  return (
    <>
    <nav className="flex items-center gap-2">
      <Menu menuButton={
          <MenuButton className="relative hover:bg-secondary-100 p-2 rounded-lg transition-colors">
            <RiNotification3Line className="text-xl"/>
            {message.length>0 && <span className="absolute -top-0.5 right-0 bg-primary py-0.5 px-[5px] box-content text-black rounded-full text-[8px] font-bold"> {message.length} </span>}
          </MenuButton>
        } align="end" arrow transition arrowClassName="bg-secondary-100" menuClassName="bg-secondary-100 p-4">
        <h1 className="text-gray-300 text-center font-medium">
          Notificaciones ({message.length})
        </h1>
        { message.length>0 && <hr className="my-6 border-gray-500" />}

        {message.map((msg, index) => {
          return (

              <MenuItem className="p-0 hover:bg-transparent"  key={index}>
                <Link to="/" className="text-gray-300 flex flex-1 items-center gap-4 py-2 px-4 hover:bg-secondary-900 transition-colors rounded-lg">
                  <RiChat3Line className="p-2 bg-yellow-200 text-yellow-700 box-content rounded-full" />
                  <div className="text-sm flex flex-col">
                    <div className="flex items-center justify-between gap-4">
                      <span className="text-gray-500" >{msg.subject}</span>{" "}
                      <span className="text-[8px]">{msg.createdAt}</span>
                    </div>
                    <p className="text-gray-500 text-xs">
                      {msg.body}
                    </p>
                  </div>
                </Link>
              </MenuItem>

          );})
        }

      </Menu>
      <Menu menuButton={
          <MenuButton className="flex items-center gap-x-2 hover:bg-secondary-100 p-2 rounded-lg transition-colors">
            <img src={ImageNoFound} alt="avatar" className="w-6 h-6 object-cover rounded-full" />
            <span className="text-xs">{auth.userName}</span>
            <RiArrowDownSLine />
          </MenuButton> } align="end" arrow arrowClassName="bg-secondary-100" transition menuClassName="bg-secondary-100 p-4" >
        <MenuItem className="p-0 hover:bg-transparent">
          <Link to={`/user/${auth.userName}`} className="rounded-lg transition-colors text-gray-300 hover:bg-secondary-900 flex items-center gap-x-4 py-2 px-6 flex-1" >
            <img src={ImageNoFound} alt="avatar" className="w-8 h-8 object-cover rounded-full" />
            <div className="flex flex-col text-xs">
              <span className="text-xs">{auth.userName}</span>
              <span className="text-xs text-gray-500">{auth.email}</span>
            </div>
          </Link>
        </MenuItem>
        <hr className="my-4 border-gray-500" />
        <MenuItem className="p-0 hover:bg-transparent">
          <Link to="/configuracion" className="rounded-lg transition-colors text-gray-300 hover:bg-secondary-900 flex items-center gap-x-4 py-2 px-6 flex-1" >
            <RiSettings3Line /> Configuración
          </Link>
        </MenuItem>
        <MenuItem className="p-0 hover:bg-transparent" onClick={onLogout}>
          <Link to="/" className="rounded-lg transition-colors text-gray-300 hover:bg-secondary-900 flex items-center gap-x-4 py-2 px-6 flex-1">
            <RiLogoutCircleRLine /> Cerrar sesión
          </Link>
        </MenuItem>
      </Menu>
    </nav>
    </>
  );
}
export default RightHeaderComponent;
