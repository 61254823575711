import React, {useState, useEffect} from 'react';
//import {Link} from "react-router-dom";

import {RiArrowDownSLine, RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import {HiOutlineInformationCircle} from "react-icons/hi";
import {Menu, MenuItem, MenuButton} from '@szhsin/react-menu';

import { boxes } from "../../__mocks__/boxes";
import CardBoxComponent from "../CardBoxComponent";
import LoadScreenComponent from '../ui/LoadScreenComponent';
import {getTicketPaid, getChildrenPaid} from '../../services/InvoiceService';
import { MAX_CHILD_TREE , MATRIX_TYPE} from "../../utils/constants";
import {getFormattedPrice , getPreciseConvertDollarBTC} from '../../utils/winforUtil';

//const getFormattedPrice = (price) => `$${price.toFixed(2)}`;

export default function AlfaComponent(props){
  const {userId} = props;
  const [invoicePaid, setInvoicePaid] = useState({});
  const [childrenPaid, setChildrenPaid] = useState([]);
  //const [boxes, setBoxes] = useState([]);
  //let [boxId, setBoxId] = useState(1);
  let [box, setBox] = useState(boxes[0]);
  //const [boxId, setBoxId] = useState(box.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const  [activeBox, setActiveBox] = useState(false);
  //let ticket = {};
  //let [ticketCol, setTicketCol] = useState([]);
  //const [ticket, setTicket] = useState();
  useEffect(async ()=>{
    console.log("consulto facturas pagadas"+userId);
    //setBoxes(boxesCol);
    fetchTicketChildPaid();
    /*await getTicketPaid({boxId:boxId, userId:userId}).then(paid => {
      setInvoicePaid(paid[0]);
    });
    await getChildrenPaid({boxId:boxId, managerId:managerId}).then(childPaid => {
      setChildrenPaid(childPaid[0]);
    });*/
  }, []);

  const fetchTicketChildPaid = async (boxIde) =>{
    setIsLoading(true);
    console.log(boxIde);

    const ticketPaid = await getTicketPaid({boxId:boxIde ? boxIde :box.id ,
      userId:userId});
    setInvoicePaid(ticketPaid[0]);
    const childPaid = await getChildrenPaid({boxId:boxIde ? boxIde :box.id,
      managerId:userId});
    setChildrenPaid(childPaid[0]);
    setIsLoading(false);
    setIsRefresh(false);
  }

  if(isLoading ){
    return <LoadScreenComponent/>;
  }
  let ticket = {};
  let ticketBeta = {};
  const ticketPaid = () => {
   //console.log(childrenPaid);
   //if(childrenPaid){
    let totalAmount = invoicePaid? invoicePaid.n_amount : 0;
    let childrenN = childrenPaid? childrenPaid.n_children: 0;
    let quotient = Math.floor(childrenN/MAX_CHILD_TREE);
    let remainder = childrenN%MAX_CHILD_TREE;
    //console.log(totalAmount);
    let total = remainder*box.price;
    let inversion = total*box.interest;
    let recive = total-inversion;
    let activeTicket = totalAmount >=1 ? true : false;



      if(isRefresh){
         ticket = {
            ticket:`(Ticket ${totalAmount})`,
            totalTickets:box.name,
            activeTicket: activeTicket,
            numAproveChild: remainder,
            countOrder: quotient,
            total: total,
            inversion:remainder===0 ? inversion :0,
            recive:remainder===0 ? recive :0,
          };
      }else{
        const numAproveChild = remainder>=0 ? MAX_CHILD_TREE : remainder;
         ticket = {
            ticket:`(Ticket ${totalAmount})`,
            totalTickets:box.name,
            activeTicket: activeTicket,
            numAproveChild: numAproveChild,
            countOrder: quotient,
            total: remainder>=0 ? MAX_CHILD_TREE*box.price : total,
            inversion:remainder>=0 ? MAX_CHILD_TREE*box.price*box.interest :0,
            recive:remainder>=0 ? MAX_CHILD_TREE*box.price*(1-box.interest) :0,
          };

      }
      ticketBeta = ticket;

};
  ticketPaid();
  const handleOnMenuBox = (boxId) => {
    const boxOn = boxes.find(({id}) => id === boxId);
    setBox(boxOn);
    //debugger;
    fetchTicketChildPaid(boxOn.id);
  }

  const onRefreshTickets = (refresh) =>{
    //console.log("refresco cajas hijos", refresh);
    setIsRefresh(refresh);
    ticketPaid();
  }

  console.log("ticker",ticket);
  return (<>
    <div className="flex flex-col" >

      <div className="flex items-start mb-5">
        <div className="md:visible invisible flex items-center justify-between h-12 bg-gradient-to-r from-yellow-500 to-orange-500 rounded-xl">
          <div className="flex items-center gap-0 text-3xl mr-4 bg-yellow-500 text-white font-bold h-full w-12 rounded-xl" >
            <HiOutlineInformationCircle className=""/>
          </div>
          <h1 className="md:truncate truncate md:text-clip md:text-black font-bold h-full py-3">Selecciona las casillas con las que deseas iniciar tus operaciones</h1>
        </div>
        <Menu className="" onItemClick={(event) => handleOnMenuBox(event.value)} menuButton={<MenuButton className="flex items-center box-border border-2 border-gray-500 rounded-lg transition-colors px-2 m-3 text-base" >{box.name} <RiArrowDownSLine /> </MenuButton>}
           arrow arrowClassName="bg-secondary-100" transition menuClassName="bg-secondary-100 p-4">
           {boxes.map((box, index) => {
             return (
                 <MenuItem className="text-xs hover:bg-secondary-900 hover:text-white" key={index} value={box.id} >{box.name}</MenuItem>
             );})}
        </Menu>
        <div className="flex items-end gap-2 text-3xl m-3">
          <RiArrowLeftSLine className="rounded-lg box-border border-2 border-gray-500 hover:text-white hover:cursor-pointer  hover:bg-secondary-100 transition-colors" />
          <RiArrowRightSLine className="rounded-lg box-border border-2 border-gray-500 hover:text-white hover:cursor-pointer  hover:bg-secondary-100 transition-colors" />
        </div>
      </div>

      <div className="snap-x flex items-center justify-evenly gap-4">
      {ticket && ( <div className="flex-row flex-nowrap gap-4 w-full h-full overflow-auto touch-pan-x">
        <h1 className="text-2xl font-bold" >Matriz {MATRIX_TYPE.ALFA_LABEL}</h1>
        <CardBoxComponent ticket={ticket.ticket} totalTickets={ticket.totalTickets} activeTicket={ticket.activeTicket}
          numAproveChild={ticket.numAproveChild} countOrder={ticket.countOrder} boxOn={box} userId={userId} setIsLoading={setIsLoading}
          type={MATRIX_TYPE.ALFA_VALUE} onRefreshChildBox={onRefreshTickets}/>
        <TotalItem total={getFormattedPrice(ticket.total)} inversion={getFormattedPrice(ticket.inversion)} recive={getFormattedPrice(ticket.recive)}   type={MATRIX_TYPE.ALFA_VALUE}/>
      </div>)}
        <div className="flex-row flex-nowrap gap-4 w-full h-full overflow-auto touch-pan-x">
          <h1 className="text-2xl font-bold" >Matriz Beta</h1>
          <CardBoxComponent ticket={ticket.ticket}totalTickets={ticket.totalTickets} activeTicket={ticket.activeTicket}
            numAproveChild={ticket.countOrder} countOrder={0} boxOn={box} userId={userId} setIsLoading={setIsLoading} type={MATRIX_TYPE.BETA_VALUE}
            />
          <TotalItem total={getFormattedPrice(0)} inversion={getFormattedPrice(0)} recive={getFormattedPrice(0)} type={MATRIX_TYPE.BETA_VALUE} />
        </div>
      </div>
    </div></>
  );
}

function TotalItem({total, inversion, recive, type}){
  return(
    <div className="static bg-secondary-900 p-8 rounded-xl max-w-md h-48">
        <table className="table-fixed">
        <thead>
          <tr className="hidden sm:hidden md:hidden">
            <th>detail</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
        {type === MATRIX_TYPE.ALFA_VALUE ?
          <tr>
            <td> <p className="text-white">Total:</p></td>

            <td><p className="text-yellow-500">{total} </p></td>
          </tr>
          : "" }
          {type === MATRIX_TYPE.ALFA_VALUE ?
          <tr>
            <td><p className="text-white">Reinversión, por activación y R. colectivo:</p></td>

            <td> <p className="text-yellow-500">{inversion}</p> </td>
          </tr>
          : "" }
          <tr>
            <td><p className="text-white">Recibes:</p></td>
            <td><p className="text-yellow-500">{recive}</p></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
