import {useEffect} from 'react';
import useAuth from '../../hooks/useAuth';
import AlfaComponent from '../../components/AlfaComponent';
import {connNewUser} from '../../services/SocketService';


export default function Home(){
  //const auth = useAuth();
  const {auth, socket} = useAuth();  //tomo socket en aplicacion
  //console.log(auth);
  useEffect(()=> connNewUser(socket, auth),[socket,auth])
  return (
    <div>
      <AlfaComponent userId={auth.id} managerId={auth.managerId}/>
    </div>

  );
}
