import React, {useState} from 'react';
import {toast} from "react-toastify";
//import {BrowserRouter as Router} from "react-router-dom";

//componentes
import "./Auth.css";
import winforLogo from "../../assets/png/winforLogo.png";
import RegisterForm from "../../components/Auth/RegisterForm";
//import winforBackground from "../../assets/png/exitedbackground.png"
import LoginComponent from "../../components/Auth/LoginComponent";
import RecoverComponent from "../../components/Auth/RecoverComponent";
import {recoverUser} from "../../services/AuthService";
//import ResetPassPage from "../ResetPassPage";


export default function Auth(){
  //const [showLogin, setShowLogin] = useState(false);
  const [authType, setAuthType] = useState("signup");
  const sendRecovery = (email) =>{
    return recoverUser(email).then(data => {
    toast.success("Correo electr\u00f3nico de restablecimiento de contrase\u00f1a enviado");
    //setShowLogin(true);
    setAuthType("login");
  });
}
  return (
    <div className="flex h-screen w-full items-center justify-center bg-white-900 bg-cover bg-no-repeat" style={{backgroundImage:"url(/exitedbackground.png)", backgroundColor:'transparent', backgroundRepeat: 'no-repeat', width:'100%', height: '100%', backgroundSize: 'cover',}}>
      <div className="rounded-xl bg-gray-300 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
          <div className="text-black">
            <div className="mb-8 flex flex-col items-center">
              <img src={winforLogo} width="150" alt="Winner Forever" />
            </div>
            <div>
              <div>
                {/*showLogin ? <LoginComponent/>:<RegisterForm setShowLogin={setShowLogin} /> */}
                {authType === "login" && <LoginComponent setAuthType={setAuthType} />}
                {authType === "signup" && <RegisterForm setAuthType={setAuthType} />}
                {authType === "reset-password" && <RecoverComponent setAuthType={setAuthType} onCreate={sendRecovery} />}
              </div>

              <div>
              <p className="text-black-300">
                {authType === "login" ?(
                <>  &#191;No tienes cuenta&#63; <span onClick={() => setAuthType("signup")} className="text-black-300 font-bold transition duration-300 ease-in-out hover:underline cursor-pointer" >Reg&#237;strate</span></>
                ):
                  (
                    <>
                    Entra con tu cuenta <span onClick={() => setAuthType("login")} className="text-black-300 font-bold transition duration-300 ease-in-out hover:underline cursor-pointer">Iniciar sesi&#243;n</span>
                    </>
                  )
                }
              </p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
