import {useState} from 'react';
import  {useFormik} from 'formik';
import * as Yup from "yup";

import { AUDIT_APP, STATUS } from "../../utils/constants";

const InvitedComponent = (props) => {
  const {onInvitedUser, userId} = props;

  const emailForm = useFormik({
    initialValues:{email:"",userId: userId , createdBy: AUDIT_APP.CREATE_BY, isEnable: STATUS.NUMBER_ENABLE },
    enableReinitialize:true,
    validationSchema:Yup.object(
    {
      email: Yup.string().email("El email no es valido").required("El email es obligatorio"),
    }
    ),
    onSubmit: async (formValues)=>{
      await onInvitedUser(formValues);
    },

  });

  return (
    <>
    <div className="container rounded-xl bg-gray-300 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8  content-center ">
          <div className="flex items-center ">
              <form  onSubmit={emailForm.handleSubmit}>
              <div className="grid grid-cols-1 gap-2">
                <div className="w-64 mb-8">
                      <p className="block text-base break-normal" >Invita para completar tu ciclo</p>
                </div>
                <div className="w-64 mb-4">
                      <label className="block text-black font-bold" htmlFor="email">Direcci&#243;n de correo</label>
                      <input type="text" {...emailForm.getFieldProps("email")} placeholder="Correo electronico"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                  <div>
                    {emailForm.touched.email && emailForm.errors.email ? (<p className="mt-0 text-sm text-red-600 dark:text-red-500"><span className="font-medium font-bold">Error: </span>{emailForm.errors.email}</p>): null }
                  </div>
                </div>
                <div className="flex w-64 mt-8 justify-center text-lg">
                  <input type="submit" value="Invitar"
                   className="form-control w-full block px-6 py-2.5 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"/>
                </div>
                </div>
              </form>
          </div>
    </div>
    </>
  );
};

export default InvitedComponent;
