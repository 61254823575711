import React from 'react';


import RightHeaderComponent from "../RightHeaderComponent";


const HeaderComponent = () =>{
  return (
    <header className="h-[7vh] md:h-[10vh] p-8 flex items-start justify-end align-top">
      <RightHeaderComponent/>
    </header>
  );
}

export default HeaderComponent;
