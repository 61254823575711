import frame3 from '../../../assets/png/frame3.png'

const LoadScreenComponent = ({}) => {
  return(
    <div className="">
      <img className=""  src={frame3} alt="winfor"/>
    </div>
  );
};

export default LoadScreenComponent;
