import { API_SERVER_BACKEND, HTTP_METHODS} from "../utils/constants";

const API_SERVER_WALLET = API_SERVER_BACKEND.HOST + API_SERVER_BACKEND.WALLET;

export const createWallets = (input) =>
fetch(`${API_SERVER_WALLET}/`, {
  method: HTTP_METHODS.REQUEST_POST,
  headers:{
    "Content-Type": "application/json",
    "x-access-token": "token-value",
  },
  body: JSON.stringify({
  	"input": input
  }),
}).then((res) => res.json())
  .then((result) => {
        return result;
    }).catch((error) => console.error('Error:', error));
