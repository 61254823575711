import React, {useState, useEffect, useMemo} from 'react';
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";

import {getUsers} from '../../services/UserService';

import TableComponent from '../ui/TableComponent';
import LoadScreenComponent from '../ui/LoadScreenComponent';
import { getFormatTimeZoneDate } from '../../utils/winforUtil';
import {STATUS } from "../../utils/constants";


const UsersComponent = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async() =>{
    setIsLoading(true);
    const userls = await getUsers();
    console.log(userls);
    if(userls){
      setUsers(userls);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const usersData = useMemo(() => [...users], [users]);
  const columns = useMemo(() => [
    {
      Header: 'C\u00f3digo',
      accessor: 'id', // accessor is the "key" in the data
      Cell: ({ cell: { value } }) => { return <Link to={`/balanceInvoice/${value}`}> <p className="underline"> {value} </p></Link>}
    },
    {
      Header: 'Usuario',
      accessor: 'userName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Fecha',
      accessor: 'createdAt',
      Cell: ({ cell: { value } }) => { return <> {getFormatTimeZoneDate(value)} </>}
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ cell: { value } }) => <CheckInvoice aprove={value} />
    },
    ],[]);

    if(isLoading ){
      return <LoadScreenComponent/>;
    }

  return(
      <div className="rounded-xl bg-gray-300 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
        <h1 className="text-2xl font-bold" >Reporte usuarios </h1>
          {usersData && (<TableComponent columns={columns} data={usersData} hooks='' exportHeader={true}/>)}

      </div>
  );
};

// Custom component to render check invoice
const CheckInvoice = ({aprove}) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      <div className="">
        <AiOutlineCheckCircle className={`${aprove === STATUS.NUMBER_ENABLE? "text-green-600": "text-red-600" } text-xl  box-content rounded-xl`} />
      </div>
    </>
  );
};

export default UsersComponent;
