import React from 'react';
import {useParams} from "react-router-dom";
import userAuth from "../hooks/useAuth";
import Profile from "../components/ProfileComponent";

export default function UserPage(){
  const {username} = useParams();
  const {auth} = userAuth();  //tomo datos de la sesion
  return (
   <>
    {username === auth.userName && <Profile username = {username} />}
   </>
 );
}
