import {BehaviorSubject} from "rxjs";

import { API_SERVER_BACKEND, HTTP_METHODS} from "../utils/constants";
const API_SERVER_USER = API_SERVER_BACKEND.HOST;

export const jwt = new BehaviorSubject(null);
export const user = new BehaviorSubject(null);

export const login = (email, password) =>
  fetch(`${API_SERVER_USER}/api/auth/login`, {
    method: HTTP_METHODS.REQUEST_POST,
    headers:{
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
    	"input":{
    		email,
    		password
    	}
    }),
  }).then((res) => res.json())
    .then((result) => {
          return result;
      },
        (error) => {
          //setIsLoaded(true);
          console.log(error);
        });

export const addUser = (input)=>
  fetch(`${API_SERVER_USER}/api/users`, {
    method:HTTP_METHODS.REQUEST_POST,
    headers:{
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt.value}`
    },
    body: JSON.stringify({
    	"input": input
    }),
  }).then((res) => res.json())
  .then((result) => {
        return result;
    },
    (error) => {
    console.log(error);
  });

export const getUsers = () =>
fetch(`${API_SERVER_USER}/api/users`, {
  headers:{
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt.value}`
  },
}).then((res) => res.json())
  .then((result) => {
        return result;
    }).catch((error) => console.error('Error:', error));

export const getUser = (input) =>
fetch(`${API_SERVER_USER}/api/users/${input.id}`, {
  headers:{
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt.value}`
  },
}).then((res) => res.json())
  .then((result) => {
        return result;
    }).catch((error) => console.error('Error:', error));


export const getPerson = async (id, userName) =>
  fetch(`${API_SERVER_USER}/api/users/person`, {
    method:HTTP_METHODS.REQUEST_POST,
    headers:{
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
    	"input":{
    		id,
    		userName
    	}
    }),
  }).then((res) => res.json())
    .then((result) => {
          return result;
      },
    (error) => {
      //setIsLoaded(true);
      console.log(error);
    });


export const updateUser = async (id, user) =>
  fetch(`${API_SERVER_USER}/api/users/${id}`, {
    method: HTTP_METHODS.REQUEST_PUT,
    headers:{
      "Content-Type": "application/json",
      "x-access-token": "token-value",
    },
    body: JSON.stringify({
    	"input":{
    		user:user
    	}
    }),
  }).then((res) => res.json())
    .then((result) => {
          return result;
      },
    (error) => {
      //setIsLoaded(true);
      console.log(error);
    });
