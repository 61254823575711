import { API_SERVER_BACKEND, HTTP_METHODS} from "../utils/constants";

const API_SERVER_INVOICE = API_SERVER_BACKEND.HOST + API_SERVER_BACKEND.INVOICE;
//const API_SERVER_AUTH = "http://127.0.0.1:4000";

export const getTicketPaid = async (input) =>
  fetch(`${API_SERVER_INVOICE}/paid/`, {
    method: HTTP_METHODS.REQUEST_POST,
    headers:{
      "Content-Type": "application/json",
      "x-access-token": "token-value",
    },
    body: JSON.stringify({
    	"input": input
    }),
  }).then((res) => res.json())
    .then((result) => {
          return result;
      }).catch((error) => console.error('Error:', error));


export const getChildrenPaid = async (input) =>
  fetch(`${API_SERVER_INVOICE}/childrens/`, {
    method:HTTP_METHODS.REQUEST_POST,
    headers:{
      "Content-Type": "application/json",
      "x-access-token": "token-value",
    },
    body: JSON.stringify({
    	"input": input
    }),
  }).then((res) => res.json())
    .then((result) => {
          return result;
      }).catch((error) => console.error('Error:', error));


export const getBillsReceived = (input) =>
fetch(`${API_SERVER_INVOICE}/bills/`, {
  method: HTTP_METHODS.REQUEST_POST,
  headers:{
    "Content-Type": "application/json",
    "x-access-token": "token-value",
  },
  body: JSON.stringify({
  	"input": input
  }),
}).then((res) => res.json())
  .then((result) => {
        return result;
    }).catch((error) => console.error('Error:', error));


export const createInvoice = (input) =>
fetch(`${API_SERVER_INVOICE}/`, {
  method: HTTP_METHODS.REQUEST_POST,
  headers:{
    "Content-Type": "application/json",
    "x-access-token": "token-value",
  },
  body: JSON.stringify({
  	"input": input
  }),
}).then((res) => res.json())
  .then((result) => {
        return result;
    }).catch((error) => console.error('Error:', error));


export const getChildrens = async (input) =>
  fetch(`${API_SERVER_INVOICE}/children/`, {
    method:HTTP_METHODS.REQUEST_POST,
    headers:{
      "Content-Type": "application/json",
      "x-access-token": "token-value",
    },
    body: JSON.stringify({
    	"input": input
    }),
  }).then((res) => res.json())
    .then((result) => {
          return result;
      }).catch((error) => console.error('Error:', error));
