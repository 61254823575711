import React from 'react';
import HeaderComponent from "../components/ui/HeaderComponent";
import SidebarComponent from "../components/ui/SidebarComponent";

export default function LayoutBasic(props){
  const {children} = props;
  return (
    <div className="min-h-screen grid grid-cols-1 xl:grid-cols-6 bg-gradient-to-b from-gray-100 to-gray-300 ">
      <SidebarComponent />

      <div className="xl:col-span-5">
        <HeaderComponent />
          <div className="flex items-center justify-center mb-10 h-[90vh] p-8">
            {children}
          </div>

      </div>
    </div>
  );
}
