import React from 'react';
import {useParams} from "react-router-dom";

import InvoiceComponent from '../../components/InvoiceComponent';

const BalancePage = ({}) => {
  //const {username} = useParams();
  return(
    <div className="content-center">
      <InvoiceComponent/>
    </div>
  );
};

export default BalancePage;
