export const boxes = [
    {
        "id": 1,
        "name": "Casilla 15",
        "price": 15,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:02:14.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 2,
        "name": "Casilla 30",
        "price": 30,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:47.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 3,
        "name": "Casilla 60",
        "price": 60,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:47.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 4,
        "name": "Casilla 120",
        "price": 120,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:47.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 5,
        "name": "Casilla 240",
        "price": 240,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:47.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 6,
        "name": "Casilla 480",
        "price": 480,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:48.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 7,
        "name": "Casilla 960",
        "price": 960,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:48.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 8,
        "name": "Casilla 1920",
        "price": 1920,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:48.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 9,
        "name": "Casilla 3840",
        "price": 3840,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:48.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 10,
        "name": "Casilla 7680",
        "price": 7680,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:48.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 11,
        "name": "Casilla 15360",
        "price": 15360,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:48.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    },
    {
        "id": 12,
        "name": "Casilla 30720",
        "price": 30720,
        "interest": 0.33,
        "createdAt": "2023-02-08T19:05:48.000Z",
        "createdBy": "admin",
        "updatedAt": null,
        "updateBy": null,
        "status": 1
    }
];
