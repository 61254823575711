import React, { useMemo, useState, useEffect} from 'react';

import { AiOutlineCheckCircle, AiOutlineSave} from "react-icons/ai";
//import { Formik } from 'formik';
import {toast} from "react-toastify";

import TableComponent from '../../components/ui/TableComponent';
import LoadScreenComponent from '../../components/ui/LoadScreenComponent';
import { STATUS } from "../../utils/constants";
import {getFormatDate, getFormatTimeZoneDate } from '../../utils/winforUtil';
import { billsPay } from "../../__mocks__/billsPay";

const BillPayPage = ({}) => {
  const dia = new Date();
  const [data, setData] = useState(billsPay);
  const [originalData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo( () => [
      {
        Header: 'id',
        accessor: 'personId',
      },
      {
        Header: 'Usuario',
        accessor: 'userName',
      },
      {
        Header: 'Wallet',
        accessor: 'accNumber',
      },
      {
        Header: 'Monto',
        accessor: 'amount',
      },
      {
        Header: 'Casilla',
        accessor: 'box.price',
      },
      {
        Header: 'Comprobante',
        accessor: 'voucher',
        Cell: EditableCell,
      },
      {
        Header: 'Realizado por',
        accessor: 'updateBy',
      },
      {
        Header: 'Acci\u00f3n',
        accessor: 'status',
        Cell: ({ cell: { value } }) => { return <div className="flex justify-center "> {value === STATUS.NUMBER_ENABLE? "pagado": "pendiente"} </div>},
      },

    ],[]);

    if(isLoading ){
      return <LoadScreenComponent/>;
    }

    const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    //setSkipPageReset(true)
    console.log("actualiza input", rowIndex, columnId, value);
    if(value){
      setData(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
        console.log(data);
    }
  }

  const saveHooks =(hooks) =>{
    hooks.visibleColumns.push((columns) =>[
      ...columns,
      {
        id: "Edit",
        Header: "Guardar",
        Cell: ({row}) => { return <div className="flex justify-center " tabIndex="0"> <AiOutlineSave type="button" onClick={() => handleSaveTransaction(row.values)} className="text-xl box-content rounded-xl cursor-pointer"/> </div>}
      },
    ]);
  }

  const handleSaveTransaction = async (billPay) => {
    //const select = {invoiceId: row.values.paymentId,  managerId: row.values.managerId, boxId: row.values.boxId };
    console.log("guardar factura", billPay);
    //setSelectPay(select);
    fetchSaveTransaction(billPay);
  };

  const fetchSaveTransaction= async (bill) =>{
      try{
        const resPay = {status: 1 };//TO DO agregar servicio de guardado transaccion
        if(resPay && resPay.status === STATUS.NUMBER_ENABLE){
          toast.success("Pago actualizado correctamente");
        }else{
          toast.error("Error al actualizar pago");
        }
      }catch(e){
        toast.error("error al actualizar pago", e.message);
      }
  }
  const exportHeader={"csv": false, "xsl": true, "pdf": true, "fileName":"cuentasXpagar"};

  return (
    <div>
      <h1 className="text-2xl font-bold" >Reporte pago </h1>
      {getFormatDate(dia)}

      {/*getFormatTimeZoneDate("2023-01-11T01:32:22.000Z")
        <Table columns={columns} data={data} updateMyData={updateMyData} />
      */}
      <TableComponent columns={columns} data={data} hooks={saveHooks} updateMyData={updateMyData} exportHeader={exportHeader}/>

    </div>
  );
};

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <input type="text" name="hashNumber" placeholder="Nro." value={value} onChange={onChange} onBlur={onBlur} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

export default BillPayPage;
