import userAuth from "../../hooks/useAuth";

import UsersComponent from '../../components/UsersComponent';

const UsersPage = ({}) => {
  const {auth} = userAuth();  //tomo datos de la sesion
  return (
    <div className="content-center">
      <UsersComponent userId={auth.id} />
    </div>
  );
};

export default UsersPage;
