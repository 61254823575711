import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
//import {toast} from "react-toastify";

//import {createWallets} from "../../services/WalletService";

export default function WalletPage(props){

  const handleSubmit = (values)=>{
    props.next(values);
  };

  return (
    <>
      <Formik initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={walletValidationSchema} >
        {(formikProps) =>(
          <Form>
            <div className="grid grid-cols-1 gap-2">
              <div className="w-64 mb-8">
                    <p className="block text-base break-normal" >Felicidades as concretado tu registro con &#233;xito</p>
              </div>
              <div className="w-64 mb-4">
                    <label className="block text-black font-bold" htmlFor="dirWallet">Direcci&#243;n de wallet BTC</label>
                    <Field type="text" name="dirWallet" placeholder=""
                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    <ErrorMessage name="dirWallet" component="div" className="mt-1 text-sm text-red-600 dark:text-red-500"/>
              </div>
              <div className="flex w-64 mt-8 justify-center text-lg">
                <input type="submit" value="Continuar"
                 className="form-control w-full block px-6 py-2.5 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"/>
              </div>
            </div>

          </Form>
          )
        }
      </Formik>
    </>
  );
}

const walletValidationSchema = Yup.object({
  dirWallet: Yup.string().matches(/[a-zA-Z0-9-]*$/, "El wallet no puede tener espacios").required("El wallet de pago es obligatorio").min(10, "El wallet debe contener al menos 10 caracteres"),
}
);
