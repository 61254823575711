import React, {useState} from 'react';

const RecoverComponent = (props) =>{
  const {onCreate} = props;
  const [email, setEmail]= useState({email:"", description:""});
  const handleChange = (e) =>{
    setEmail({
      ...email,
      [e.target.name]:e.target.value
    });
  }
  const onSubmit = (e) =>{
    e.preventDefault();
    onCreate(email);
  }

  return (
    <>
    <form onSubmit={onSubmit} >
      <div className="w-64 mb-8">
            <p className="block text-base break-normal" >Te ayudamos a recuperar tu cuenta</p>
      </div>
      <div className="mb-4">
        <label className="block text-black font-bold" htmlFor="email">E-mail</label>
        <input type="text" name="email" placeholder="Correo Electr&#243;nico"
          value={email.email} onChange={(e) => handleChange(e)}
  				className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
      </div>

      <div className="mt-8 flex justify-center text-lg text-black">
        <input type="submit" value="Recuperar contrase&#241;a" className="form-control block w-full px-6 py-2.5 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"/>
      </div>
    </form>
    </>
  );
}

export default RecoverComponent;
