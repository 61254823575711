import React from 'react';
import { useTable, useGlobalFilter ,useSortBy} from 'react-table';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileText} from "react-icons/ai";
import GlobalFilterComponent from '../GlobalFilterComponent';

export default function TableComponent(props) {
  const {columns, data, hooks, globalFilter, updateMyData, exportHeader} = props;
  // Use the state and functions returned from useTable to build your UI
  const tableInstance = useTable({columns, data, updateMyData}, hooks, useGlobalFilter, useSortBy);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  return(
    <>

    <table {...getTableProps()} className="table-auto min-w-full text-center text-sm font-light border-collapse border-2 border-solid  border-black">
    <caption className="caption-top">
      <div className="flex flex-row justify-between">
      {exportHeader && <HeaderExportAction csvData={data} typeExport={exportHeader} /> }
      {globalFilter ?<GlobalFilterComponent
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={state.globalFilter}
        />: null}
        </div>
    </caption>

       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())} className="bg-neutral-500 text-black border ">
                 {column.render('Header')}
                 {column.isSorted ? (column.isSortedDesc ? "\u25B2": "\u25BC"):""}
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()} className="border-b border-black" >
               {row.cells.map(cell => {
                 return (
                   <td {...cell.getCellProps()}  className="whitespace-nowrap" >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
     </table>

     </>
  );
}

const HeaderExportAction = ({csvData, typeExport}) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
  }
      return (
        <div className="flex items-center">
          {typeExport.csv && (<button type="button" onClick={() => alert("hola")} className="flex justify-center hover:bg-blue-700 hover:shadow-lg" > <AiOutlineFileText className="text-xl box-content rounded-xl cursor-pointer"/> </button>)}
          {typeExport.xsl && (<button type="button" onClick={(e) => exportToCSV(csvData, typeExport.fileName)} className="flex justify-center hover:bg-yellow-700 hover:shadow-lg">
            <AiOutlineFileExcel className="text-xl box-content rounded-xl cursor-pointer"/>
           </button>)}
          {typeExport.pdf && (<button className="flex justify-center " > <AiOutlineFilePdf className="text-xl box-content rounded-xl cursor-pointer"/> </button>)}
      </div>
    );
  };
