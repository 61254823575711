require("dotenv").config({ path: ".env.local" });

export const TOKEN = "token";
export const SERVER_FRONTEND_HOST=process.env.REACT_APP_SERVER_FRONTEND_HOST;
export const URL_SEARCH_PARAM_INVITED ="topic";
export const MAX_CHILD_TREE = 3;  //es el orden de la estructura arbol
export const MASTER_WALLET_ATM = "662d8d8042645dd6a7d7f70ce1dc5c68f7415ce4b7334fb91e0bf6092297c568";
//id usuario admin
export const SUPER_USER_ID = 1;

export const API_SERVER_BACKEND={
    HOST: process.env.REACT_APP_API_SERVER_BACKEND_HOST,
    AUTH: "/api/auth",
    USER: "/api/users",
    INVITED: "/api/invited",
    INVOICE: "/api/invoices",
    PAYMENT: "/api/payments",
    WALLET: "/api/wallets",
    CURRENCY:"/api/cryptocurrency"
};
export const STATUS = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZE: 401,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  BOOLEAN_TRUE: true,
  NUMBER_ENABLE: 1,
  NUMBER_DISABLE: 0,
};

export const HTTP_METHODS = {
  REQUEST_GET: "GET",
  REQUEST_HEAD: "HEAD",
  REQUEST_POST: "POST",
  REQUEST_PUT: "PUT",
  REQUEST_DELETE: "DELETE",
  REQUEST_PATCH: "PATCH",
};

export const AUDIT_APP={
  UPDATE_BY: "app-web",
  CREATE_BY: "app-web",
};

export const FORMAT_APP={
    DATE_DD_MM_YYYY: "dd/MM/yyyy",
    DATE_DD_MM_YYYY_HH_MM: "dd/MM/yyyy hh:mm",
}

export const WALLET_CURRENCY ={
  IDTYPE_BTC: 1,
  WALLET_TYPE_DOLLAR:"\u0024",
  WALLET_TYPE_BTC:"BTC",
}

export const MATRIX_TYPE={
    ALFA_VALUE: 0,
    ALFA_LABEL: "Alfa",
    BETA_VALUE: 1,
    BETA_LABEL: "Beta",
}

//condiciones de ganancia matriz beta
export const BETA_MATRIX={
  N1_LEAF_CONDITION: 12,  //numero de ciclos 4
  N2_LEAF_CONDITION: 15,  //numero de ciclos 5
  N3_LEAF_CONDITION: 18,  //numero de ciclos 6
}
