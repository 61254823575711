import React, {useState, useEffect, useMemo} from 'react';
import { useTable, useGlobalFilter ,useSortBy} from 'react-table';
import { Link } from "react-router-dom";
import { Dialog } from '@headlessui/react';
import { format } from 'date-fns';
import {toast} from "react-toastify";
import { AiOutlineAlert } from "react-icons/ai";

import userAuth from "../../hooks/useAuth";
import TableComponent from '../ui/TableComponent';
import LoadScreenComponent from '../ui/LoadScreenComponent';
import GlobalFilterComponent from '../ui/GlobalFilterComponent';
import {getBillsReceived} from '../../services/InvoiceService';
import {updatePayment} from "../../services/PaymentService";
import {emitNotificationMessage} from "../../services/SocketService";
import { STATUS, AUDIT_APP} from "../../utils/constants";
import { getFormatTimeZoneDate } from '../../utils/winforUtil';


const InvoiceComponent = (props) => {
  //const {username} = props;
  const [invoices, setInvoices] = useState([]);
  const {auth, socket} = userAuth();  //tomo datos de la sesion
  const [isLoading, setIsLoading] = useState(false);
  let [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  let [payId, setPayId]  = useState(0);
  let [selectPay, setSelectPay]  = useState({});

  useEffect(async ()=>{
    console.log("consulto facturas usuario ", auth.id, socket);
    await fetchInvoices();
  },[]);

  const fetchInvoices = async () =>{
    setIsLoading(true);
    const invoicesToCheck = await getBillsReceived({});
    setInvoices(invoicesToCheck);
    console.log(invoicesToCheck);
    setIsLoading(false);
  }

  //const data = React.useMemo( () => invoices , []);
  const columns = useMemo(
     () => [
       {
         Header: "Invoices show",
         columns: [
           {
             Header: 'C\u00f3digo',
             accessor: 'user.id',
           },
           {
             Header: 'Usuario',
             accessor: 'user.userName', // accessor is the "key" in the data
           },
           {
             Header: 'Email',
             accessor: 'user.email',
           },
           {
             Header: 'Monto',
             accessor: 'invoiceDetails[0].rate',
           },
           {
             Header: 'No. Ticket',
             accessor: 'invoiceDetails[0].amount',
           },
           {
             Header: 'Comprobante',
             accessor: 'payment[0].payHash',
           },
           {
             Header: 'Deposito de casilla',
             accessor: 'invoiceDetails[0].box.price',
           },
           {
             Header: 'Aprobado',
             accessor: 'payment[0].aproveInvoice',
             Cell: ({ cell: { value } }) => <CheckInvoice aprove={value} />
           },
           {
             Header: 'Fecha',
             accessor: 'payment[0].createdAt',
             Cell: ({ cell: { value } }) => { return <> {getFormatTimeZoneDate(value)} </>}
           },
           {
             id:'boxId',
             Header: 'boxId',
             accessor: 'invoiceDetails[0].boxId',
           },
           {
             id:'managerId',
             Header: 'managerId',
             accessor: 'user.managerId',
           },
           {
             id:'paymentId',
             Header: 'Factura id',
             accessor: 'payment[0].id',
           },
         ]
       }

     ],
     []
   );

   if(isLoading ){
     return <LoadScreenComponent/>;
   }
   const tableHooks=(hooks)=>{
     hooks.visibleColumns.push((columns) =>[
       ...columns,
       {
         id: "Edit",
         Header: "Editar",
         Cell:({row}) => (
           <button onClick={() => handleActiveEdit(row)} className="form-control block px-6 py-1 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out">
              Editar
            </button>
         ),
       },
     ]);
   };

   const handleActiveEdit = (row) => {
     //setActiveBox(!activeBox);
     //console.log("activo modal Editar");
     //alert("Editar" + JSON.stringify(row.values.paymentId));
     setIsOpenModalEdit(true);
     //console.log("Editar" + JSON.stringify(row.values));
     setPayId(row.values.paymentId);
     const select = {invoiceId: row.values.paymentId,  managerId: row.values.managerId, boxId: row.values.boxId };
     //console.log(select);
     setSelectPay(select);
   };
   const exportHeader={"csv": true, "xsl": true, "pdf": true, "fileName":"facturasAprobadas"};
  return (
    <div className="rounded-xl bg-gray-300 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8 content-center items-center">
      <div className="mb-5 ">
        <h1 className="text-2xl font-bold" >Aprobar facturas</h1>
      </div>
      <TableComponent columns={columns} data={invoices} hooks={tableHooks} globalFilter={true} exportHeader={exportHeader} />
      <EditBillModal  invoiceSlc={selectPay} isOpenModal={isOpenModalEdit} setIsOpen={setIsOpenModalEdit} fetchInvoices={fetchInvoices} socket={socket}/>
    </div>
  )
};

// Custom component to render check invoice
const CheckInvoice = ({aprove}) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      <div className="">
        <AiOutlineAlert className={`${aprove === STATUS.NUMBER_ENABLE? "text-green-600": "text-red-600" } text-xl  box-content rounded-xl`} />
      </div>
    </>
  );
};

function EditBillModal({invoiceId, isOpenModal, setIsOpen, fetchInvoices, socket, invoiceSlc}){

  const handleActionBill = async (action) => {
    //setActiveBox(!activeBox);
    console.log("action ", action);
    await updateBill(invoiceSlc.invoiceId,{ payment:{
    updateBy: AUDIT_APP.UPDATE_BY,
    isEnable: STATUS.NUMBER_ENABLE,
    aproveInvoice : action
    }, } );
    await fetchInvoices();
    //type 1: alert 2: message
    handleEmitNotification(1);
    setIsOpen(false);
  };

  const handleEmitNotification = (type) =>{
  //  console.log("Notificacion", type);
    const input = {input:{ managerId: invoiceSlc.managerId, boxId: invoiceSlc.boxId , type: type}};
    emitNotificationMessage(socket, input);
  }

  const updateBill = async (id, input) =>{
    try{
      const resPay = await updatePayment(id, input);
      if(!resPay){
        toast.error("Error al actualizar pago");
      }else{
        toast.success("Pago actualizado correctamente");
      }
    }catch(e){
      toast.error("error al actualizar pago", e.message);
    }
  }

  return (
    <Dialog open={isOpenModal} onClose={(e) => setIsOpen(e)} className="modal fade relative z-50">
     <Dialog.Panel className="modal fade fixed left-0 top-0 px-16 py-10 h-full backdrop-blur-md">
     <div className="rounded-xl px-16 py-10 shadow-lg bg-gray-300 max-sm:px-8 content-center items-center">
     <Dialog.Title className="font-bold">Editar pago</Dialog.Title>
       <Dialog.Description>
         Esto desactivar&#225; permanentemente la factura.
       </Dialog.Description>

       <p>
         Seguro que quieres desactivar la factura&#63;
         <span className="font-bold" > {invoiceSlc.invoiceId} </span>
       </p>

        <div className="grid grid-cols-2 gap-4">
          <button className="form-control block px-6 py-1 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={() => handleActionBill(STATUS.NUMBER_ENABLE)}>Valido</button>
          <button className="form-control block px-6 py-1 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"
            onClick={() => handleActionBill(STATUS.NUMBER_DISABLE)}>No valido</button>
        </div>
       </div>
     </Dialog.Panel>
   </Dialog>)
}
export default InvoiceComponent;
