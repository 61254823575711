export const billsPay =[
	{
		"userId": 1,
		"personId": 1,
		"userName": "admin",
		"accNumber": "1CHnYDdHiqwfJhrfkF2cPwwpgcbcQsU3tH",
		"payment": [
			{
				"aproveInvoice": 1
			}
		],
		"box": {
			"id": 1,
			"price": 15,
			"interest": 0.33
		},
		"children": 6,
		"amount": 90,
		"tax": 29.7,
    "voucher":"pojgesadfkjpoerjdkfioerjdfkier",
    "createdAt": "2023-02-08T19:05:47.000Z",
    "createdBy": "admin",
    "updatedAt": "2023-02-08T19:05:47.000Z",
    "updateBy": "web-user",
    "status": 1
	},
	{
		"userId": 2,
		"personId": 2,
		"userName": "sguerrero",
		"accNumber": "2CHnYDdHiqwfJhrfkF2cPxxpgcbcQsU3tH",
		"payment": [
			{
				"aproveInvoice": 1
			}
		],
		"box": {
			"id": 1,
			"price": 15,
			"interest": 0.33
		},
		"children": 3,
		"amount": 45,
		"tax": 14.85,
    "createdAt": "2023-02-08T19:05:47.000Z",
    "createdBy": "admin",
    "updatedAt": "2023-02-08T19:05:47.000Z",
    "updateBy": "web-user",
    "status": 1
	},
	{
		"userId": 66,
		"personId": 46,
		"userName": "vivichi",
		"accNumber": "iuopweruijsdfjwoeijsodjfoiwejsjdfkowej",
		"payment": [
			{
				"aproveInvoice": 1
			}
		],
		"box": {
			"id": 1,
			"price": 15,
			"interest": 0.33
		},
		"children": 5,
		"amount": 45,
		"tax": 14.85,
    "createdAt": "2023-02-08T19:05:47.000Z",
    "createdBy": "admin",
    "updatedAt": "2023-02-08T19:05:47.000Z",
    "updateBy": "web-user",
    "status": 1
	}
]
