import React from 'react';
import {RiCloseLine} from 'react-icons/ri';


const  ModalComponent= ({ onHide, header, children }) => {
  const onClose = (event) => {
          onHide();
          event.preventDefault();
      };

  return(
        <div className="fix items-center justify-center modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-gray-300 bg-opacity-50 z-50"
          onClick={onClose} tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none">
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                      <h5 className="text-xl font-medium leading-normal text-gray-800">
                        {header? header: "Modal title"}
                      </h5>
                      <button type="button" className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                       onClick={onClose} data-bs-dismiss="modal" aria-label="Close"><RiCloseLine/></button>
                    </div>
                    <div className="modal-body relative p-4">
                      { children ? children :
                        <div>
                          <p>This is some placeholder content to show a vertically centered modal. We've added some extra copy here to show how vertically centering the modal works when combined with scrollable modals. We also use some repeated line breaks to quickly extend the height of the content, thereby triggering the scrolling. When content becomes longer than the predefined max-height of modal, content will be cropped and scrollable within the modal.</p>
                          <p>Just like that.</p>
                        </div>
                      }
                    </div>
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  <button type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out"
                    data-bs-dismiss="modal" onClick={() => onHide()} > Close </button>
                  <button type="button" className="inline-block px-6 py-2.5 bg-yellow-500 text-black font-medium text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                    Save changes
                  </button>
                </div>
          </div>
        </div>
      </div>
  );
}

export default ModalComponent;
