import React, { useState, useEffect } from "react";
import  {useFormik} from 'formik';
import * as Yup from "yup";
import {toast} from "react-toastify";

import { AUDIT_APP, STATUS } from "../../utils/constants";
import {getPerson, updateUser} from "../../services/UserService";

// Icons
import {
  RiEyeLine,
  RiEyeOffLine,
} from "react-icons/ri";



export default function ProfilePage(props){

  const {username} = props;
  const [showPassword, setShowPassword] = useState(false);
  let [data, setData] = useState();
  //let error, loading = null;
  //let {data, loading, error } = null;


  //getPerson(null,username).then((value) =>{ data=value; loading=true; }, (err) => { error= err; });
  //let result = response.json();
  //console.log(data);
  //if(!loading) return null;
  //if(!error) return <h1>Usuario no encontrado</h1>;
  useEffect(()=>{
    getPerson(null,username).then((value) =>{
      setData(value);
      //loading=true;
    });
  }, []);

  const personForm = useFormik({
    initialValues:initialValues(data),
    enableReinitialize:true,
    validationSchema:Yup.object(
      {
        firstName: Yup.string().required("El nombre es obligatorio").min(3, "El nombre debe contener al menos 3 caracteres"),
        lastName: Yup.string().required("El apellido es obligatorio").min(3, "El apellido debe contener al menos 3 caracteres"),
        userName: Yup.string().matches(/[a-zA-Z0-9-]*$/, "El nickname no puede tener espacios").required("El nombre de usuario es obligatorio").min(3, "El nickname debe contener al menos 3 caracteres"),
        email: Yup.string().email("El email no es valido").required("El email es obligatorio"),
        address: Yup.string().required("El pa\u00eds es obligatorio"),
        currentPassword: Yup.string().required("La contrase\u00f1a es obligatoria").min(5, "La contrase\u00f1a debe contener al menos 5 caracteres"),
        newPassword: Yup.string().required().oneOf([Yup.ref("repeatNewPassword")]).min(5, "La contrase\u00f1a debe contener al menos 5 caracteres"),
        repeatNewPassword: Yup.string().required().oneOf([Yup.ref("newPassword")]).min(5, "La contrase\u00f1a debe contener al menos 5 caracteres"),
      }
    ),
    onSubmit: async (formValues)=>{
      try{
        const result = await updateUser( formValues.id, {
          			userName: formValues.userName,
          			email: formValues.email,
              	currentPassword: formValues.currentPassword,
              	newPassword: formValues.newPassword,
          		  status: 1,
          		  updateBy: AUDIT_APP.UPDATE_BY,
        		    person: {
        		        id: formValues.personid,
        		        firstName: formValues.firstName,
        		        lastName: formValues.lastName,
        		        address: formValues.address,
        		        phone: formValues.phone,
        		        status: 1,
        		        wallet: {
        		        	id: formValues.walletid,
        		          accNumber: formValues.wallet
        		        },
        		    }
          });
        if(!result){
            toast.error("Error al actualizar perfil");
        }else{
          console.log("cambio ok"); //TO DO eliminar sesion al cambiar
            toast.success("cambio ok");
        }
      }catch(e){
        toast.error("error al actualizar perfil"+e.message);
      }
    },
  });
    //if(!loading) return null;
    /*if(loading){
      console.log({data});
      personForm.setValues({data});
    }*/
  return (
    <>
      {/* Profile */}
      <div className="rounded-xl bg-gray-300 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8 content-center">
            <div className="items-center w-64">
                <h2 className="text-2xl font-bold">Perfil</h2>

                <form onSubmit={personForm.handleSubmit}>
                  <div className="form-group ">
                    <div className="mb-4">
                          <label className="block text-black font-bold" htmlFor="firstName">Nombre</label>

                          <input type="text" name="firstName" placeholder="Nombre" value={personForm.values.firstName}
                          onBlur={personForm.handleBlur} onChange={personForm.handleChange}
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    </div>
                    <div className="mb-4">
                          <label className="block text-black font-bold" htmlFor="lastName">Apellido</label>
                          <input type="text" {...personForm.getFieldProps("lastName")}
                          placeholder="Apellido"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    </div>

                    <div className="mb-4">
                          <label className="block text-black font-bold" htmlFor="userName">Nickname</label>
                          <input type="text" {...personForm.getFieldProps("userName")} placeholder="Nickname"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    </div>

                    <div className="mb-4">
                          <label className="block text-black font-bold" htmlFor="email">E-mail</label>
                          <input type="text" {...personForm.getFieldProps("email")} placeholder="Correo electronico"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    </div>

                    <div className="mb-4">
                      <label className="block text-black font-bold" htmlFor="address">Pa&#237;s</label>
                      <input type="text" {...personForm.getFieldProps("address")} placeholder="Pa&#237;s"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    </div>

                    <div className="mb-4">
                      <label className="block text-black font-bold" htmlFor="phone">Telefono</label>
                      <input type="tel" {...personForm.getFieldProps("phone")} placeholder="Telefono"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    </div>
                    <div className="mb-4">
                          <label className="block text-black font-bold" htmlFor="wallet">Wallet btc</label>
                          <input type="text" {...personForm.getFieldProps("wallet")} placeholder="wallet"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                    </div>

                    <div className="mb-4">
                      <label className="block text-black font-bold" htmlFor="currentPassword">Contrase&#241;a actual</label>
                      <input type="Password" {...personForm.getFieldProps("currentPassword")}  placeholder="*********"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                      <div>
                        {personForm.touched.currentPassword && personForm.errors.currentPassword ? (<p className="mt-0 text-sm text-red-600 dark:text-red-500"><span className="font-medium font-bold">Error: </span>{personForm.errors.currentPassword}</p>): null }
                      </div>
                    </div>

                    <div className="relative mb-4">
                      <label className="block text-black font-bold" htmlFor="newPassword">Nueva contrase&#241;a</label>
                      <div className="flex items-center">
                        <input type={showPassword ? "text" : "password"} {...personForm.getFieldProps("newPassword")}  placeholder="*********"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                        {showPassword ? (
                          <RiEyeOffLine className="absolute top-1/2 -translate-y-1/2 right-2 hover:cursor-pointer text-primary"
                            onClick={() => setShowPassword(!showPassword)} />
                        ) : (
                          <RiEyeLine className="absolute top-1/2 -translate-y-1/2 right-2 hover:cursor-pointer text-primary"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </div>
                      <div>
                        {personForm.touched.newPassword && personForm.errors.newPassword ? (<p className="mt-0 text-sm text-red-600 dark:text-red-500"><span className="font-medium font-bold">Error: </span>{personForm.errors.currentPassword}</p>): null }
                      </div>
                    </div>

                    <div className="relative mb-4">
                      <label className="block text-black font-bold" htmlFor="repeatNewPassword">Repetir contrase&#241;a</label>
                      <div className="flex items-center">
                        <input type={showPassword ? "text" : "password"} {...personForm.getFieldProps("repeatNewPassword")}  placeholder="*********"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                        {showPassword ? (
                          <RiEyeOffLine className="absolute top-1/2 -translate-y-1/2 right-2 hover:cursor-pointer text-primary"
                            onClick={() => setShowPassword(!showPassword)} />
                        ) : (
                          <RiEyeLine className="absolute top-1/2 -translate-y-1/2 right-2 hover:cursor-pointer text-primary"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </div>
                    </div>

                    <div className="mt-8 flex justify-center text-lg text-black">
                      <input type="submit" value="Guardar" className="form-control block w-full px-6 py-2.5 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"/>
                    </div>

                  </div>
                </form>

            </div>
        </div>
    </>
 );
}

function initialValues(data){
  return(
    { firstName: data? data.person.firstName: "",
      lastName: data? data.person.lastName: "",
      userName: data? data.userName: "",
      email: data? data.email: "",
      address: data? data.person.address: "",
      currentPassword: "",
      newPassword: "",
      repeatNewPassword:"",
      phone: data? data.person.phone: "",
      wallet: data? data.person.wallet.accNumber : "",
      status: STATUS.NUMBER_ENABLE ,
      id : data? data.id: "",
      personid : data? data.person.id: "",
      walletid : data? data.person.wallet.id: "",
    }
  );
}
