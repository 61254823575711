import React, {useState} from 'react';
import {toast} from "react-toastify";


import {decodeToken} from "../../../utils/token";
import {addUser} from "../../../services/UserService";
import {createWallets} from "../../../services/WalletService";
import {createInvoice} from "../../../services/InvoiceService";
import { boxes } from "../../../__mocks__/boxes";

import RegisterComponent from "../RegisterComponent";
import WalletComponent from "../../WalletComponent";
import PayComponent from "../../PayComponent";

import { AUDIT_APP, WALLET_CURRENCY, SUPER_USER_ID, URL_SEARCH_PARAM_INVITED} from "../../../utils/constants";

export default function RegisterForm(props){
  //const {tokenFather} = useParams();
  const siteUrl = window.location.search;
  const urlParams = new URLSearchParams(siteUrl);
  let fatherId = SUPER_USER_ID;
  //console.log( siteUrl);
  //console.log(urlParams.get("topic"));
  try{
      const tokenFather = urlParams.get(URL_SEARCH_PARAM_INVITED);
      const fatherUser = decodeToken(tokenFather);
      //console.log(fatherUser.userId);
      fatherId = fatherUser.userId ? fatherUser.userId : SUPER_USER_ID;
  }catch(e){
    console.log("register not topic",e.message);
  }

  const defaultBox = boxes[0];
  const [data, setData] = useState(initialValues(defaultBox));
  const [currentStep, setCurrenStep]= useState(0);

  const handleNextStep = (newData, final = false) =>{
    setData(prev => ({...prev, ...newData})); //propagamos el estado anterior en el nuevo estado

    if(final){
      fetchMakeRequest(newData);
      return
    }

    setCurrenStep(prev => prev + 1);
  };

  const handlePrevStep = (newData) =>{
    setData(prev => ({...prev, ...newData})); //dejamos los datos como estan para seguir completando
    setCurrenStep(prev => prev - 1);
  };
  const steps = [<RegisterComponent next={handleNextStep} data={data} />,<WalletComponent next={handleNextStep} data={data} prev={handlePrevStep}/>,
    <PayComponent next={handleNextStep} data={data}/>,];

  const fetchMakeRequest = async (formData) =>{
    //console.log("Form submit",formData);
    //alert(JSON.stringify(formData, null, 2));
    try{
      //const {dirWallet} = formData;
      const newUser = await addUser({
          	userName: formData.userName,
          	email: formData.email,
          	pass: formData.password,
          	managerId: fatherId,
          	createdBy: AUDIT_APP.CREATE_BY,
          	person:{
          		firstName: formData.firstName,
          		lastName: formData.lastName,
          		address: formData.address,
          		phone: formData.phone,
              createdBy: AUDIT_APP.CREATE_BY,
          	}
        });
          if(!newUser){
             toast.error("Error al registrar usuario");
           }else{
             console.log(newUser);
             const result = await createWallets({
                  accNumber: formData.dirWallet,
                  personId: newUser.person.id,
                  createdBy: AUDIT_APP.CREATE_BY,
                  currencyId: WALLET_CURRENCY.IDTYPE_BTC,
      	         });
              if(!result){
                toast.error("Error al registrar wallet");
              }else{
                const newInvoice = await createInvoice({
                  createdBy: AUDIT_APP.CREATE_BY,
                  userId: newUser.id,
                  invoiceDetail: {
                      amount: formData.amountTotal,
                      rate: formData.rate,
                      createdBy: AUDIT_APP.CREATE_BY,
                      boxId: 1
                  },
                  payment: {
                      payHash: formData.payHash,
                      createdBy: AUDIT_APP.CREATE_BY
                  }
                });
                toast.success("Usuario registrado correctamente");
              }
           }

       }catch(e){
         toast.error("Error al registrar usuario" + e.message);
       }
    //const newUser = await addUser();
  }
  return (
    <>
      {steps[currentStep]}
    </>
  );
}

function initialValues(box){
  return(
    { firstName: "",
      lastName: "",
      userName: "",
      email: "",
      address: "",
      password: "",
      phone:"",
      accept: false,
      dirWallet:"",
      payHash: "",
      rate: box.price,
      createdAt: new Date(),
      amountTotal : 1,  //cantidad total por defecto 
      boxOn: box,
    }
  );
}
