import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export default function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter,}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);
  return (
    <div className="flex items-center">
      <p className="font-bold">Buscar:</p>
      <input type="text "value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`} className="form-control block w-64 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out mb-2  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"
      />
    </div>
  )
}
/*export default function GlobalFilterComponent({filter, setFilter}) {

  return (
    <div className=" mb-6 mt-6 flex items-center">
      <p>Buscar</p>
      <input value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  )

}*/
