import { API_SERVER_BACKEND, HTTP_METHODS } from "../utils/constants";

const API_SERVER_CURRENCY = API_SERVER_BACKEND.HOST + API_SERVER_BACKEND.CURRENCY;

export const getCurrencyList = (input) =>
fetch(`${API_SERVER_CURRENCY}/listings/latest?start=${input.start}&limit=${input.limit}&convert=${input.convert}`, {
  headers:{
    "Content-Type": "application/json",
    "x-access-token": "token-value",
  },
}).then((res) => res.json())
  .then((result) => {
        return result;
    }).catch((error) => console.error('Error:', error));
