import React, {useState, useEffect, useMemo} from 'react';
//import { useTable, useGlobalFilter ,useSortBy} from 'react-table';
import join from 'lodash/join';
import {toast} from "react-toastify";

//import userAuth from "../../hooks/useAuth";
import {getBillsReceived} from '../../services/InvoiceService';
import {getUser, updateUser} from "../../services/UserService";
import {getChildrens} from '../../services/InvoiceService';

import TableComponent from '../ui/TableComponent';
import LoadScreenComponent from '../ui/LoadScreenComponent';
import { AiOutlineCheckCircle } from "react-icons/ai";
//import GlobalFilterComponent from '../ui/GlobalFilterComponent';
import { getFormatTimeZoneDate } from '../../utils/winforUtil';
import { AUDIT_APP, STATUS } from "../../utils/constants";

const DepositComponent = (props) => {

  const {userId} = props;
  //const {auth} = userAuth();  //tomo datos de la sesion
  const [isLoading, setIsLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [userData, setUserData] = useState();
  const [userEnable, setUserEnable] = useState(STATUS.NUMBER_DISABLE);
  const [boxes, setBoxes] = useState([]);
  const [childrens, setChildrens] = useState([]);

  const fetchBills = async () =>{
    setIsLoading(true);
    const invoicesToCheck = await getBillsReceived({userId: userId});
    if(invoicesToCheck){
      setBills(invoicesToCheck);
      //console.log(invoicesToCheck);
      let boxData = [];
      invoicesToCheck.map((item)=>{
          //console.log();
          const invoicesDetails = item.invoiceDetails;
          invoicesDetails.map((invoice) => { boxData.push(invoice.box.price);});
      });
      //console.log(boxData);
      setBoxes(boxData);
      setIsLoading(false);
    }
  }

  const fetchUserDescription = async() =>{
    setIsLoading(true);
    const userRes = await getUser({id:userId});
    if(userRes){
      setUserData(userRes);
      setUserEnable(userRes.status);
      setIsLoading(false);
    }
  }

  const fetchUserChilds = async() =>{
    setIsLoading(true);
    const childsRes = await getChildrens({managerId: userId});
    if(childsRes){
      setChildrens(childsRes);
      setIsLoading(false);
    }
  }

  const fetchUpdateUser = async() =>{
    //setIsLoading(true);
    const state = userEnable === STATUS.NUMBER_ENABLE? STATUS.NUMBER_DISABLE : STATUS.NUMBER_ENABLE;
    try{
      const userUpdate = {
              status: state,
              updateBy: AUDIT_APP.UPDATE_BY,
              person: {
                  id: userData.person.id,
                  status: state,
                  wallet: {
                    id: userData.person.wallet.id,
                    status: state,
                  },
              }
        }
      console.log(userUpdate);
      const result = await updateUser( userId, userUpdate);
      console.log(result);

      if(result[0]===STATUS.NUMBER_ENABLE){
        setUserEnable(state);
        toast.success("Cambio ok");
      }else{
          toast.error("Error al bloquear usuario");
      }
    }catch(e){
      toast.error("error al bloquear "+ e.message);
    }
  }

  useEffect(() => {
    fetchBills();
    fetchUserDescription();
    fetchUserChilds();
  }, [userEnable]);

  const billsData = useMemo(() => [...bills], [bills]);
  const columns = useMemo(() => [
    {
      Header: 'Valor',
      accessor: 'invoiceDetails[0].box.price',
    },
    {
      Header: 'Deposito de casilla',
      accessor: 'invoiceDetails[0].box.name',
    },
    {
      Header: 'Aprobado',
      accessor: 'payment[0].aproveInvoice',
      Cell: ({ cell: { value } }) => <CheckInvoice aprove={value} />
    },
    ],[]);

  /*const billsColumns = useMemo(
    () =>
      bills[0]
        ? Object.keys(bills[0])
            .filter((key) => key !== "rating")
            .map((key) => {
              return { Header: key, accessor: key };
            })
        : [],
    [bills]
  );*/

  const childsData = useMemo(() => [...childrens], [childrens]);
  const columnsChilds = useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'Cliente ingresado',
      accessor: 'userName',
    },
    {
      Header: 'Casilla',
      accessor: 'invoices[0].invoiceDetails[0].box.name',
    },
    {
      Header: 'Monto ingresado',
      accessor: 'invoices[0].invoiceDetails[0].rate',
    },
    ],[]);

  if(isLoading ){
    return <LoadScreenComponent/>;
  }

  const handleUpdateUserState = ()=>{
    console.log("cambiar estado usuario", userId);
    fetchUpdateUser();
  }

  return (
    <div className="rounded-xl bg-gray-300 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
    {userData ?  <div className="flex justify-around p-8 ">
      <div className="pr-4 mr-8">
        <h1 className="text-sm font-bold" >Usuario</h1>
        <p className="text-sm" >{userData.userName}</p>
        <h1 className="text-sm font-bold mb-1" >Montos ingresados</h1>
        <TableComponent columns={columns} data={billsData} hooks=''/>
      </div>
      <div className="items-center justify-between bg-white p-8 rounded-xl">
          <h1 className="text-sm font-bold" >Datos de usuario</h1>
          <div className=" grid-cols-1 md:grid-cols-5 gap-4 mb-10 p-4">
            <h5>Usuario desde: <span>{getFormatTimeZoneDate(userData.createdAt)}</span> </h5>
            <h5>Correo electronico: <span>{userData.email}</span> </h5>
            <h5>ID: <span>{userData.id}</span></h5>
            <h5>Casillas:  <span>{join(boxes, ',')}</span> </h5>
            <h5>BTC generados: <span>25</span></h5>
          </div>

        {userEnable === STATUS.NUMBER_ENABLE ? <input type="submit" value="Desactivar"  onClick={handleUpdateUserState}
         className="form-control block px-6 py-1 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 cursor-pointer"/>
         : <input type="submit" value="Activar"  onClick={handleUpdateUserState}
          className="form-control block px-6 py-1 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 cursor-pointer"/>
       }
        </div>
      </div> : null }
      <div className="flex items-center justify-between p-8 rounded-xl">
        <TableComponent columns={columnsChilds} data={childsData} hooks=''/>

      </div>
    </div>
  );
}

// Custom component to render check invoice
const CheckInvoice = ({aprove}) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      <div className="">
        <AiOutlineCheckCircle className={`${aprove === STATUS.NUMBER_ENABLE? "text-green-600": "text-red-600" } text-xl  box-content rounded-xl`} />
      </div>
    </>
  );
};


export default DepositComponent;
