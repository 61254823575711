import LayoutBasic from "../layouts/LayoutBasic";
//paginas
import HomePage from "../pages/HomePage";
import Error404 from "../pages/Error404";
import UserPage from "../pages/UserPage";
//import SectionPage from "../pages/SectionPage";
//import PayPage from "../pages/PayPage";
//import WalletPage from "../pages/WalletPage";
//import ResetPassPage from "../pages/ResetPassPage";
import NotificationPage from "../pages/NotificationPage";
import {ReportPage, ReportUserPage } from "../pages/ReportPage";
import BalancePage from "../pages/BalancePage";
import BillPayPage from "../pages/BillPayPage";
import UsersPage from "../pages/UsersPage";


const routes=[
  {
    path:"/",
    component:HomePage,
    exact: true,
    layout: LayoutBasic
  },
  {
    path:"/user/:username",
    component:UserPage,
    exact: true,
    layout: LayoutBasic
  },
  {
    path:"/notification/:username",
    component:NotificationPage,
    exact: true,
    layout: LayoutBasic
  },
  {
    path:"/report",
    component:ReportPage,
    exact: true,
    layout: LayoutBasic
  },
  {
    path:"/balanceInvoice/:userId",
    component:ReportUserPage,
    exact: true,
    layout: LayoutBasic
  },
  {
    path:"/balanceInvoice/",
    component:BalancePage,
    exact: true,
    layout: LayoutBasic
  },
  {
    path:"/billsPay",
    component:BillPayPage,
    exact: true,
    layout: LayoutBasic
  },
  {
    path:"/users",
    component:UsersPage,
    exact: true,
    layout: LayoutBasic
  },
  {
    component:Error404,
    layout: LayoutBasic
  }
]

export default routes;
