import React, { useState, useEffect } from 'react';
//import {NavLink} from 'react-router-dom';
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
// Icons
import {
  RiBarChart2Line,
  RiLogoutCircleRLine,
  RiArrowRightSLine,
  RiMenu3Line,
  RiCloseLine,
  RiNotification3Line,
  RiMoneyDollarCircleLine,
  RiFileCopyLine
} from "react-icons/ri";
import { SiBitcoin } from "react-icons/si";
import {FcLineChart} from "react-icons/fc";
import {HiOutlineDocumentReport, HiOutlineUserGroup} from "react-icons/hi";

//import { AiOutlineUser } from "react-icons/ai";

import {getTicketPaid, getChildrenPaid, getBillsReceived} from '../../../services/InvoiceService';
import {createInvited} from "../../../services/InvitedService";
import {getCurrencyList} from "../../../services/CryptoCurrencyService";

import winforLogo from "../../../assets/png/winforLogo-lg.png";
import { SERVER_FRONTEND_HOST, URL_SEARCH_PARAM_INVITED, STATUS, AUDIT_APP } from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";
import {getFormattedPrice, getFormatConvertDollarBTC, getPreciseConvertDollarBTC } from '../../../utils/winforUtil';

const SidebarComponent = () => {
  const {auth, logout} = useAuth();
  const [showMenu, setShowMenu] = useState(true);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [copyText, setCopyText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [childrenNumber, setChildrenNumber] = useState();
  const [bills, setBills] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [totalInvoicesBeta, setTotalInvoicesBeta] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const userId = auth.id;

  useEffect(async ()=>{
    console.log("consulto hijos sidebar");
    fetchChildPayed();
    fetchBills();
    fetchInvitedToken();
    fetchCryptoCurrency();
  }, []);

  const fetchBills = async () =>{
    setIsLoading(true);
    const invoicesToCheck = await getBillsReceived({userId: userId, aprove: STATUS.NUMBER_ENABLE});
    if(invoicesToCheck){
      let totalPrice = 0;
      setBills(invoicesToCheck);
      //sumBillsAprove();
      //console.log("sumo facturas", invoicesToCheck);
      invoicesToCheck.map((item)=>{
          //console.log();
          const invoicesDetails = item.invoiceDetails;
          invoicesDetails.forEach((invoice) => {totalPrice = totalPrice + invoice.box.price;});
      });
      //console.log(totalPrice);
      setTotalInvoices(totalPrice);
      setIsLoading(false);
  }
  }

  const fetchChildPayed = async () =>{
    setIsLoading(true);
    const childPaid = await getChildrenPaid({ managerId: userId });
    setChildrenNumber(childPaid.length);
    setIsLoading(false);
  }

  const fetchInvitedToken = async () =>{
    setIsLoading(true);
    const resInvited = await createInvited(
      {
        userId: userId,
        createdBy: AUDIT_APP.CREATE_BY,
        isEnable: STATUS.NUMBER_ENABLE
	     });
    if(resInvited){
        setCopyText(SERVER_FRONTEND_HOST + '/?' + URL_SEARCH_PARAM_INVITED + '=' + resInvited.invitedToken);
        setIsLoading(false);
    }
  }

  const fetchCryptoCurrency = async () =>{
    setIsLoading(true);
    const currencyLst = await getCurrencyList(
      {
        start: 1,
        limit:1,
        convert:"USD"
      });
      const data = currencyLst.data;
      const foundBTC = data.find(element => element.symbol ===  "BTC");
      console.log(foundBTC.quote.USD);
      setUsdPrice(foundBTC.quote.USD.price);
    //setChildrenNumber(childPaid.length);
    setIsLoading(false);
  }

  if(isLoading ){
    return <div>cargando...</div>;
  }

  const handleCopyText = (e) => {
         setCopyText(e.target.value);
      } 
  const copyToClipboard = () => {
       copy(copyText);
       alert(`You have copied "${copyText}"`);
  }

  const sumBillsAprove = async() =>{
    let totalPrice = 0;
    console.log("sumo facturas", bills);
    if(isLoading){
      bills.map((item)=>(
        console.log(item.invoiceDetails)
      ));
    }
  }

  const createAppLinks = () =>{
    return(
      <ul className="mb-5">
        <li>
          <Link to="/" className="flex items-center gap-4 py-2 px-4 rounded-lg hover:bg-secondary-900 transition-colors text-gray-100">
            <RiBarChart2Line className="text-primary" /> Inicio
          </Link>
        </li>

        <li>
          <Link to={`/notification/${auth.userName}`} className="flex items-center gap-4 py-2 px-4 rounded-lg hover:bg-secondary-900 transition-colors text-gray-100">
            <RiNotification3Line className="text-primary" /> Notificaciones
          </Link>
        </li>
        <li>
          <button onClick={() => setShowSubmenu(!showSubmenu)} className="w-full flex items-center justify-between py-2 px-4 rounded-lg hover:bg-secondary-900 transition-colors text-gray-100">
            <span className="flex items-center gap-4">
              <RiMoneyDollarCircleLine className="text-primary" /> Estados de cuenta
            </span>
            <RiArrowRightSLine className={`mt-1 ${ showSubmenu && "rotate-90" } transition-all`} />
          </button>
          <ul className={` ${ showSubmenu ? "h-[130px]" : "h-0" } overflow-y-hidden transition-all`} >
            <li>
              <Link to={`/balanceInvoice/`} className="py-2 px-4 border-l border-gray-500 ml-6 block relative before:w-3 before:h-3 before:absolute before:bg-primary before:rounded-full before:-left-[6.5px] before:top-1/2 before:-translate-y-1/2 before:border-4 before:border-secondary-100 hover:text-white transition-colors" >
                Validar facturas
              </Link>
            </li>
            <li>
              <Link to="/billsPay" className="py-2 px-4 border-l border-gray-500 ml-6 block relative before:w-3 before:h-3 before:absolute before:bg-gray-500 before:rounded-full before:-left-[6.5px] before:top-1/2 before:-translate-y-1/2 before:border-4 before:border-secondary-100 hover:text-white transition-colors">
                Cuentas por pagar
              </Link>
            </li>
            <li>
              <Link to="/users" className="py-2 px-4 border-l border-gray-500 ml-6 block relative before:w-3 before:h-3 before:absolute before:bg-gray-500 before:rounded-full before:-left-[6.5px] before:top-1/2 before:-translate-y-1/2 before:border-4 before:border-secondary-100 hover:text-white transition-colors">
                Adm. usuarios
              </Link>
            </li>
            <li>
              <Link to="/tickets" className="py-2 px-4 border-l border-gray-500 ml-6 block relative before:w-3 before:h-3 before:absolute before:bg-gray-500 before:rounded-full before:-left-[6.5px] before:top-1/2 before:-translate-y-1/2 before:border-4 before:border-secondary-100 hover:text-white transition-colors">
                Estadisticas
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/report" className="flex items-center gap-4 py-2 px-4 rounded-lg hover:bg-secondary-900 transition-colors text-gray-100" >
            <HiOutlineDocumentReport className="text-primary" /> Reportes
          </Link>
        </li>
        {/*<li>
          <Link to="/" className="flex items-center gap-4 py-2 px-4 rounded-lg hover:bg-secondary-900 transition-colors text-gray-100" >
            <AiOutlineUser className="text-primary" /> Perfil
          </Link>
        </li>*/}

      </ul>
    );
  };

  const createAfilationLink = () => {
        return (
          <div className="relative mb-4 w-64">
            <label className="block text-white text-sm" htmlFor="passRep">Link de afiliaci&#243;n</label>
            <div className="flex">
              <input type="text" name="copyLink" value={copyText} onChange={handleCopyText} 
              className="form-control block w-full px-3 py-1.5 text-sm font-normal text-black bg-primary bg-clip-padding border border-solid border-primary rounded transition ease-in-out m-0  focus:text-black focus:bg-primary focus:border-yellow-500 focus:outline-none" readOnly disabled />
              <RiFileCopyLine className="absolute top-1/2 -translate-y-1/2 right-2 hover:cursor-pointer text-black" onClick={copyToClipboard}/>
            </div>
          </div>
        );
    };

  const createAlfaBalance = ()=>{
    return(
      <div className="flex flex-col gap-2 py-2 px-4 rounded-lg bg-secondary-900 transition-colors text-gray-100">
        <h1 className="flex justify-between text-sm text-gray-100">Matriz alfa: <span className="text-sm  text-gray-100">{getPreciseConvertDollarBTC(105, usdPrice)} {getFormattedPrice(105)}</span></h1>
      </div>
    );
  };

  const createBetaBalance = ()=>{
    return(
      <div className="flex flex-col gap-2 py-2 px-4 rounded-lg bg-secondary-900 transition-colors text-gray-100 mt-1">
        <h1 className="flex justify-between text-sm text-gray-100">Matriz beta: <span className="text-sm text-gray-100">{getPreciseConvertDollarBTC(0.0000,usdPrice)} {getFormattedPrice(0)}</span></h1>
      </div>
    );
  };

  const createBtcCurrency = ()=>{
    return(
      <div className="flex flex-col gap-2 py-2 px-4 rounded-lg bg-secondary-900 transition-colors text-gray-100">
        <div className="flex justify-between" > <SiBitcoin className="text-primary" /> <FcLineChart className="text-3xl" /></div>
        <h1 className="flex justify-between text-sm text-gray-100">Bitcoin BTC:<span className="text-sm  text-gray-100">  {getFormattedPrice(usdPrice)}</span></h1>
      </div>
    );
  };

  const afilationLink = createAfilationLink();
  const applicationLink = createAppLinks();
  const alfaBalance = createAlfaBalance();
  const betaBalance = createBetaBalance();
  const btcCurrency = createBtcCurrency()
  return(
    <>
      <div className={`xl:h-[100vh] overflow-y-scroll fixed w-[30%] md:w-[40%] lg:w-[80%] xl:w-auto h-full top-0 bg-secondary-100 flex flex-col justify-between z-50 rounded-lg
        ${ showMenu ? "left-0" : "-left-full" } transition-all`}>

        <div className="max-w-full bg-white rounded-t-lg items-center flex justify-center">
          <img src={winforLogo} alt="winfor" className="max-w-full h-auto rounded-lg" />
        </div>
        <div className="p-4 rounded-lg mb-10">
          <div className="flex flex-wrap gap-x-3 justify-between mb-2">
            <h1 className="text-base font-bold text-white"> Bienvenido, {auth.userName} </h1>
            <div className="flex flex-row">
              <HiOutlineUserGroup className="mt-1 text-primary" /> <p className=" text-white">{childrenNumber}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 py-2 px-4 mb-5 rounded-lg bg-secondary-900 transition-colors text-gray-100">
            <h1 className="flex justify-between text-sm text-gray-100">Beneficio total <span className="text-base font-bold">{getPreciseConvertDollarBTC(totalInvoices, usdPrice)}</span></h1>
          </div>
          {btcCurrency}
          {applicationLink}
          {afilationLink}
          {alfaBalance}
          {betaBalance}
        </div>
        <nav onClick={logout}>
          <Link to="/" className="flex items-center gap-4 py-2 px-4 rounded-lg hover:bg-secondary-900 transition-colors text-gray-100" >
            <RiLogoutCircleRLine className="text-primary" /> Cerrar sesi&#243;n
          </Link>
        </nav>
      </div>
      <button onClick={() => setShowMenu(!showMenu)} className="xl:hidden fixed bottom-4 right-4 bg-primary text-black p-3 rounded-full z-50" >
        {showMenu ? <RiCloseLine /> : <RiMenu3Line />}
      </button>
    </>
  )
};

export default SidebarComponent;
