import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

import "./LoginComponent.css";
import {login} from "../../../services/UserService";
import {setToken, decodeToken} from "../../../utils/token";
import useAuth from "../../../hooks/useAuth";

export default function LoginComponent(props) {
	const [error, setError] = useState("");
	const {setUser} = useAuth();
	const {setAuthType } = props;

	const formikLogin= useFormik({
    initialValues: initialValues(),
    validationSchema:Yup.object(
      {
        email: Yup.string().email("El email no es valido").required("El email es obligatorio"),
        password: Yup.string().required("La contrase\u00f1a es obligatoria"),
      }
    ),
    onSubmit:async (formData) =>{
      console.log("ingreso a login usuario");
			setError("");
      try{
				const data = await login(formData.email, formData.password);
				const token = data.token;
				//console.log(token);
				setToken(token);
				setUser(decodeToken(token));
      }catch(error){
        //toast.error(error.message)
				setError(error.message);
      }
    },

  });
  return (
    <>
    <form onSubmit={formikLogin.handleSubmit}>
        <div className="mb-2">
          <label className="block text-black font-bold" htmlFor="email">E-mail</label>
          <input type="text" name="email" placeholder="Correo Electr&#243;nico"
						value={formikLogin.values.email}
						onBlur={formikLogin.handleBlur}
						onChange={formikLogin.handleChange}
						className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
						<div>
								{formikLogin.touched.email && formikLogin.errors.email ? (<p className="mt-0 text-sm text-red-600 dark:text-red-500"><span className="font-medium font-bold">Error: </span>{formikLogin.errors.email}</p>): null }
						</div>

				</div>
        <div className="mb-4">
          <label className="block text-black font-bold" htmlFor="password">Contrase&#241;a</label>
          <input type="Password" name="password" placeholder="*********"
						value={formikLogin.values.password}
						onBlur={formikLogin.handleBlur}
						onChange={formikLogin.handleChange}
					  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
						<div>
								{formikLogin.touched.password && formikLogin.errors.password ? (<p className="mt-0 text-sm text-red-600 dark:text-red-500"><span className="font-medium font-bold">Error: </span>{formikLogin.errors.password}</p>): null }
						</div>
				</div>

				<span onClick={() => setAuthType("reset-password")} className="text-end text-black-600 hover:text-black-700 focus:text-black-700 transition duration-200 ease-in-out cursor-pointer hover:underline">
					Olvidaste el password?
				</span>
        <div className="mt-8 flex justify-center text-lg text-black">
          <input type="submit" value="Iniciar Sesi&#243;n" className="form-control block w-full px-6 py-2.5 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"/>
        </div>
				{error && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{error} </p>}
      </form>
    </>
  );
}

function initialValues(){
	return(
		{
			email:"",
			password:"",
		}
	);
}
