import React from 'react';
import DepositComponent from '../../components/DepositComponent';
import {useParams} from "react-router-dom";

import userAuth from "../../hooks/useAuth";

const ReportPage = ({}) => {
  const {auth} = userAuth();  //tomo datos de la sesion

  return(
    <div> <DepositComponent userId={auth.id} /> </div>
  );
};

export default ReportPage;
