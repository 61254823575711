import { format, parseISO} from 'date-fns';

import {WALLET_CURRENCY} from "./constants";

export const getFormattedPrice = (price) => `${WALLET_CURRENCY.WALLET_TYPE_DOLLAR}${price.toFixed(2)}`;

export const getFormatConvertDollarBTC = (price, value) => `${WALLET_CURRENCY.WALLET_TYPE_BTC}: ${(price/value).toFixed(2)}`;

export const getFormatDate = (date) => { return format(date, 'dd/MM/yyyy hh:mm:ss');};

//const formattedTimeStamp = format(parseISO("2023-01-11T01:32:22.000Z"), "yyyy/MM/dd hh:mm:ss").toUpperCase();
//{new Intl.DateTimeFormat('en-US',{year:'numeric', month:'short', day:'2-digit'}).format(new Date(Date.parse(dia)))}
export const getFormatTimeZoneDate = (date) => { return format(parseISO(date), "dd/MM/yyyy hh:mm:ss").toUpperCase()};

export const getPreciseConvertDollarBTC = (x, value) => {
  if (Number.isNaN(value) || value === 0 ) {
    return 0;
  }
  let digits = 6;
  const size = x.toFixed().length;
  if(x>1){
    if(size === 2){
      digits = digits-1;
    }else if(size === 3){
      digits = digits-2;
    }else if(size === 4){
      digits = digits-3;
    }else{
      digits = digits-4;
    }
  }
  return `${WALLET_CURRENCY.WALLET_TYPE_BTC}: ${(x/value).toFixed(digits)}`
  //return x.toPrecision(4);
}
