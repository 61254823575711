import React,{useState} from 'react';
import { useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import { Dialog } from '@headlessui/react';

import userAuth from "../../hooks/useAuth";
import InvitedComponent from "../../components/InvitedComponent";
//import ModalComponent from "../../components/ui/ModalComponent";
import {createInvited, sendInvited} from "../../services/InvitedService";
import { URL_SEARCH_PARAM_INVITED } from "../../utils/constants";

const NotificationPage = (props) => {
  const {username} = useParams();
  const [isOpenModal, setIsOpenModal] = useState(true);
  const {auth} = userAuth();  //tomo datos de la sesion

  let history = useHistory();

  function closeSendModal(event) {
    //event.preventDefault();
    setIsOpenModal(false);
    history.goBack();
  }

  const sendInvitedEmail = async (email) =>{
    console.log(email);
    try{
      const resInvited = await createInvited(email);
      if(!resInvited){
        toast.error("Error al enviar invitaci\u00f3n ");
      }else{
        console.log(resInvited);
        const token = '/?' + URL_SEARCH_PARAM_INVITED + '=' +resInvited.invitedToken;
        await sendInvited({"email":email.email, "token":token});
        toast.success("Correo electr\u00f3nico de invitaci\u00f3n enviado");
      }
    }catch(e){
      toast.error("error al enviar invitaci\u00f3n"+e.message);
    }
  }


  return (
    <>{/*isOpenModal && <ModalComponent header=" "  children={<SendMailComponent/>} onHide={(e) => handlerModal()} /> */}
    {username === auth.userName && <Dialog open={isOpenModal} onClose={(e) => closeSendModal(e)} className="modal fade relative z-50">
       <Dialog.Panel className="modal fade fixed left-0 top-0 px-16 py-10 h-full backdrop-blur-md">
          <InvitedComponent onInvitedUser={sendInvitedEmail} userId={auth.id}></InvitedComponent>
       </Dialog.Panel>
     </Dialog>}
    </>
  );
};

export default NotificationPage;
