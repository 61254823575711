import React, {useState} from 'react';

import { BsArrowClockwise} from "react-icons/bs";
import { IoMdRadioButtonOn } from "react-icons/io";
import { Switch } from "@headlessui/react";
import { Dialog } from '@headlessui/react';
import {toast} from "react-toastify";

import {createInvoice} from "../../services/InvoiceService";
import bitcoinIcon from "../../assets/png/bitcoinIcon.png";
//import ModalComponent from '../../components/ui/ModalComponent';
import PayComponent from "../../components/PayComponent";
//import LoadScreenComponent from '../ui/LoadScreenComponent';
import { MAX_CHILD_TREE, AUDIT_APP, MATRIX_TYPE} from "../../utils/constants";



const DEFAULT_CHILD_BOX = MAX_CHILD_TREE;

const CardBoxComponent = (props) => {

  const { ticket, totalTickets, activeTicket,numAproveChild, countOrder, boxOn, userId, setIsLoading, type, onRefreshChildBox} = props;
  const [activeBox, setActiveBox] = useState(activeTicket);
  const  [disableTicket, setDisableTicket] = useState(activeTicket);
  const [payData, setPayData] = useState(payInitialValues(boxOn));

  let [isOpen, setIsOpen] = useState(false);
  const handleActiveBox = () => {
    setActiveBox(!activeBox);
    setIsOpen(true);
    console.log("activo check");
  };

  function closePayModal(event) {
    setIsOpen(false);
    setActiveBox(!activeBox);
    //event.preventDefault();
  }

  /*function openModal(event) {
    setIsOpen(true);
    event.preventDefault();
  }*/

  const handleNextPay = (newData, final = false) =>{
    setPayData(prev => ({...prev, ...newData})); //propagamos el estado anterior en el nuevo estado
    fetchCreateInvoice(newData);
  };

  const fetchCreateInvoice = async (formData) =>{
    setIsLoading(true);
    try{
      //console.log("crea invoice cardbox",formData);
      const newInvoice = await createInvoice({
        createdBy: AUDIT_APP.CREATE_BY,
        userId: userId,
        invoiceDetail: {
            amount: formData.amountTotal,
            rate: formData.rate,
            createdBy: AUDIT_APP.CREATE_BY,
            boxId: formData.boxOn.id,
        },
        payment: {
            payHash: formData.payHash,
            createdBy: AUDIT_APP.CREATE_BY
        }
      });
      if(!newInvoice){
          toast.error("Error al registrar casilla");
      }else{
        closePayModal();
        toast.success("Usuario registrado correctamente");
      }
    }catch(e){
      toast.error("Error al registrar casilla" + e.message);
    }finally{
      setIsLoading(false);
    }
  }

  return (
    <div className={`${type === MATRIX_TYPE.BETA_VALUE ? "bg-yellow-100": "bg-yellow-200" } static p-8 rounded-xl h-48 max-w-md mb-4 shadow-lg`} >
    <div className="flex items-center justify-between mb-1">
      {/* casillas */}
      <div>
        <p className="text-black font-bold text-xl text-justify ">{ticket} {totalTickets}</p>
      </div>

      <div>
      <Switch.Group>
        <Switch checked={activeBox} onChange={handleActiveBox} disabled={disableTicket}
                  className={`${ activeBox ? "bg-primary" : "bg-secondary-100" } relative inline-flex h-6 w-11 items-center rounded-full`} >
                  <span className="sr-only">Enable notifications</span>
                  <span className={`${ activeBox ? "translate-x-6 bg-secondary-900" : "translate-x-1 bg-yellow-500" } inline-block h-4 w-4 transform rounded-full transition`} />
        </Switch>
        <Switch.Label className={`${activeBox? "text-green-600 font-bold": "text-black" } p-2`}>Activo</Switch.Label>
      </Switch.Group>
      </div>

      <div>
        <IoMdRadioButtonOn className={`${activeBox? "text-green-600": "text-gray-900" } text-xl  box-content rounded-xl`} />
      </div>
    </div>

      <div>
        <hr className="border border-gray-500 my-4" />
      </div>
      <div className="flex items-center gap-4 justify-between mb-1">
        <ApproveChild numAproveChild={numAproveChild} countOrder={countOrder} type={type} onRefreshChildBox={() => onRefreshChildBox(true)}/>
      </div>
      <Dialog open={isOpen} onClose={(e) => closePayModal(e)} className="modal fade relative z-50">


         <Dialog.Panel className="modal fade fixed left-0 top-0 px-16 py-10 h-full backdrop-blur-md">

         <div className="rounded-xl px-16 py-10 shadow-lg bg-gray-300 max-sm:px-8 content-center items-center">
           <PayComponent data={payData} next={handleNextPay} />
          </div>
         </Dialog.Panel>
       </Dialog>
    </div>
  );
}

function ApproveChild({numAproveChild, countOrder, type, onRefreshChildBox}){
  var elements=[];
        for(var i=0;i<DEFAULT_CHILD_BOX;i++){
            elements.push(<div key={i} className="flex items-center">
              <p className="text-base px-2">{i+1}.</p>
              { i < (numAproveChild) ?
                (<div className="flex items-center gap-0 bg-black border-2 border-black rotate-45" >
                  <img src={bitcoinIcon} alt="..." className="max-w-full h-auto align-middle border-none" />
                </div>)
              :
                (<div className="flex items-center gap-0 bg-white border-black border-2 rotate-45" >
                  <img src={bitcoinIcon} alt="..." className="md:invisible max-w-full h-auto align-middle border-none" />
                </div>)
              }
              </div>);
        }
  return(
  <>
    {elements}
    <p className="flex items-center gap-2 text-black text-left break-words">
       {type === MATRIX_TYPE.ALFA_VALUE ? <><BsArrowClockwise  onClick={() => onRefreshChildBox()} /> {countOrder} Ciclos completados</>: <span className="m-4 bg-yellow-100 text-white font-bold py-4 whitespace-pre">             </span>}
    </p>
  </>
  );
}

function payInitialValues(box){
  return(
    {
      rate: box.price,
      createdAt: new Date(),
      amountTotal : 1,
      boxOn: box,
      payHash:"",
    }
  );
}

export default CardBoxComponent;
