import React,{useState}  from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {toast} from "react-toastify";
import copy from "copy-to-clipboard";
import { RiFileCopyLine } from "react-icons/ri";
import WinforQrCode from "../../assets/png/winforQr.png";

import { MASTER_WALLET_ATM} from "../../utils/constants";

const PayComponent = (props) => {
  const masterWallet = MASTER_WALLET_ATM;
  const { data } = props;
  console.log(data);
  const [copyText, setCopyText] = useState(masterWallet);

  const copyToClipboard = () => {
         copy(copyText);
         alert(`You have copied "${copyText}"`);
      }
  const handleSubmit = (values)=>{
    props.next(values, true);
  };

  return (
    <Formik
    initialValues={data}
    onSubmit={handleSubmit}
    validationSchema={payValidationSchema}
    >
    {(formikProps) =>(
      <Form>
        <div className="grid grid-cols-1 gap-2">
            <div className="w-64 mb-4">
              <label className="block text-base font-bold" htmlFor="rate">Has seleccionado la casilla</label>
              <Field type="text" name="boxOn.name" placeholder="Casilla" readOnly disabled
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-yellow-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
            </div>
            <div className="relative w-64 mb-4">
              <label className="block text-sm break-normal font-bold" htmlFor="wallet">Env&#237;a en BTC el valor seleccionado a la siguiente direccion wallet</label>
              <div className="flex">
                <input type="text" name="wallet" placeholder={masterWallet}  readOnly disabled
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-secondary-100 bg-clip-padding border border-solid border-yellow-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
                <RiFileCopyLine className="absolute top-1/2 -translate-y-1/2 right-2 hover:cursor-pointer text-black" onClick={copyToClipboard}/>
              </div>
            </div>
            <div className="flex justify-center w-64">
              <img src={WinforQrCode} alt="" className="w-32 h-32 object-cover" />
            </div>
            <div className="w-64">
                  <p className="block text-base break-normal" >Y pega tu comprobante de transacci&#243;n aqui abajo</p>
            </div>

            <div className="w-64 mb-4">
              <label className="block text-black font-bold" htmlFor="payHash">Ingresa hash de confirmaci&#243;n</label>
              <Field type="text" name="payHash" placeholder=""
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0  focus:text-gray-700 focus:bg-white focus:border-yellow-500 focus:outline-none"  />
              <ErrorMessage name="payHash" component="div" className="mt-1 text-sm text-red-600 dark:text-red-500" />

            </div>

            <div className="flex w-64 mt-8 justify-center text-lg">
              <input type="submit" value="Enviar"
               className="form-control w-full block px-6 py-2.5 bg-yellow-500 text-black font-bold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 hover:shadow-lg focus:bg-yellow-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out"/>
            </div>
          </div>

      </Form>
      )
    }
  </Formik>
  );
};

const payValidationSchema = Yup.object({
  payHash: Yup.string().matches(/[a-zA-Z0-9-]*$/, "El hash no puede tener espacios").required("El hash de pago es obligatorio").min(10, "El hash debe contener al menos 10 caracteres"),
  rate: Yup.number().required().positive().integer(),
  createdAt: Yup.date().default(() => new Date()),
  amountTotal: Yup.number().required().positive(),
}
);


export default PayComponent;
