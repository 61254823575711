import { io } from "socket.io-client";
import { API_SERVER_BACKEND, HTTP_METHODS} from "../utils/constants";

const API_SERVER_INVOICE = API_SERVER_BACKEND.HOST + API_SERVER_BACKEND.PAYMENT;

export const connection = () =>{
  const socket = io(API_SERVER_BACKEND.HOST, {
    withCredentials:false,
    extraHeaders:{
      "x-access-token": "token-value"
    }
  });
  return socket;
}

export const connNewUser =(socket, input) =>{
  return socket?.emit("connNewUser",{input: input });
}

export const emitNotificationMessage = (socket, input) =>{
  console.log("envia emit", socket)
  //if (input) {
    const result = socket.emit('sendNotiMessage', input);
    //input.value = '';
  //}
  return result;
}

export const getNotifications =(socket) =>{
  //console.log("buscar notificaciones socket");
  let notifications =[];
  socket.on("getNotification", data =>{
    notifications= data;
  });
  return notifications;
}

/*fetch(`${API_SERVER_INVOICE}/${id}`, {
  method: HTTP_METHODS.REQUEST_PUT,
  headers:{
    "Content-Type": "application/json",
    "x-access-token": "token-value",
  },
  body: JSON.stringify({
  	"input": input
  }),
}).then((res) => res.json())
  .then((result) => {
        return result;
    }).catch((error) => console.error('Error:', error));
*/
