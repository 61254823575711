import React, {useState, useEffect, useMemo} from "react";
import {ToastContainer} from "react-toastify";
//componentes
import Auth from "./pages/Auth";
import {getToken, decodeToken, removeToken} from "./utils/token";
import {connection} from "./services/SocketService";
import AuthContext from "./context/AuthContext";
import Navigation from "./routes/Navigation";


function App() {
  const [auth, setAuth] = useState(undefined);
  const [socket, setSocket] = useState(null);
  //const authData= {name: "admin prueba"};
  useEffect(()=>{
    const token = getToken();
    console.log("ingredo a la aplicacion");
    if(!token){
      setAuth(null);
    }else{
      setAuth(decodeToken(token));
    }
  },[]);

  useEffect(()=>{
    const sock = connection();
    //console.log(sock);
    setSocketIo(sock);
  },[])

  const logout = () => {
    //console.log("cerrar sesion");
    removeToken();  //elimino token del localStorage
    setAuth(null);  //seteo estado null llevar al registro
  };

  const setUser = (user) =>{
    setAuth(user);
  };

  const setSocketIo = (socket) =>{
    setSocket(socket);
  };

  const authData = useMemo(
    () =>({
      auth,
      logout,
      setUser,
      socket,
    }),[auth]
  );

  if(auth === undefined) return null;

  return (

      <div>
        <AuthContext.Provider value={authData}>
        {!auth ? <Auth/>: <Navigation/>}
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick={true}
          pauseOnHover draggable rtl={false} pauseOnFocurLoss/>
        </AuthContext.Provider>
      </div>
  );
}

export default App;
